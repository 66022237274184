import React from 'react'
import styled, { css } from 'react-emotion'
import ImagesContext from './ImagesContext'
import Img from 'gatsby-image'
import { PANEL_COLOR } from '../constants'

export default ({ path, ...rest }) => {
  if (path.endsWith('.gif') || path.endsWith('.svg')) {
    return <img src={`/assets/images/${path}`} alt="" />
  }

  return (
    <ImagesContext.Consumer>
      {allImages => (
        <StyledImage
          outerWrapperClassName={inlineBlock}
          backgroundColor={PANEL_COLOR}
          fluid={allImages[path].fluid}
          {...rest}
        />
      )}
    </ImagesContext.Consumer>
  )
}

const innerShadow = css`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0 40px 4px rgba(25, 25, 25, .67);
  }
`

const inlineBlock = css`
  display: inline;
`

const StyledImage = styled(Img)`
  ${props => props.innerShadow && innerShadow};
`
