import React from 'react'
import styled from 'react-emotion'
import { COLOR, CONTRAST_COLOR } from '../constants'
import SyntaxHighlighter from 'react-syntax-highlighter/prism'
import { okaidia } from 'react-syntax-highlighter/styles/prism'
import Caption from './Caption'
import Image from './Image'

export const PostTitle = styled.div`
  color: ${CONTRAST_COLOR};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  border-color: rgba(250, 24, 81, 0.4);
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
`

export const LastUpdated = styled.div`
  color: ${COLOR};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  font-weight: 400;
  font-size: 14px;
`

export const Author = styled.div`
  color: ${COLOR};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  font-weight: 700;
  font-size: 18px;
  font-style: italic;
`

export const Article = styled.div`
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  margin: 40px 0;
`

const VideoContainer = styled.div`
  position: relative;
  width: 2560px;
  max-width: 100%;
  padding-bottom: 56.25%;
`

export const List = styled.ul`
  margin-left: 40px;
  margin-bottom: 1em;

  & > li {
    margin-bottom: 5px;

    & > p {
      display: inline-block;
    }

    &:before {
      content: '–';
      margin-right: 10px;
    }
  }
`

export const Bold = styled.b`
  color: ${CONTRAST_COLOR};
`

export const Separator = styled.div`
  font-size: 2em;
  line-height: 2em;

  &:before {
    content: '***';
  }
`

const IllustrationContainer = styled.div`
  margin-bottom: 1em;
`

export const Illustration = ({ src, caption }) => (
  <IllustrationContainer>
    <Image path={src} />
    {caption && <Caption>{caption}</Caption>}
  </IllustrationContainer>
)

export const Video = ({ src, caption }) => (
  <IllustrationContainer>
    <VideoContainer>
      <iframe
        title="Video"
        width="100%"
        height="100%"
        style={{ position: 'absolute' }}
        src={src}
        frameBorder="0"
        allowFullScreen=""
      />
    </VideoContainer>
    {caption && <Caption>{caption}</Caption>}
  </IllustrationContainer>
)

export const Code = ({ children }) => (
  <SyntaxHighlighter language="javascript" style={okaidia}>
    {children}
  </SyntaxHighlighter>
)
