import React from 'react'
import styled from 'react-emotion'
import { FONT_SMALL } from '../constants'

const Container = styled.div`
  font: ${FONT_SMALL};
  padding: 0;
  margin-top: 5px;
`

export default ({ children }) => <Container>{children}</Container>
