import React, { Fragment } from 'react'
import rehypeReact from 'rehype-react'
import { get } from 'lodash/fp'
import '../components/prism-darkula.css'
import ImagesContext from '../components/ImagesContext'

import {
  Article,
  Bold,
  Illustration,
  List,
  LastUpdated,
  Author,
  PostTitle,
  Separator,
  Video,
} from '../components/Blog'
import SEO from '../components/SEO'
import { H2 } from '../components/Style'
import Link from '../components/Link'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h2: H2,
    a: Link,
    player: Video,
    pic: Illustration,
    hr: Separator,
    ul: List,
    strong: Bold,
  },
}).Compiler


export default ({ data }) => {
  const { path, title, date, author, mainImage } = data.post.frontmatter
  const rtl = path.includes('hebrew')

  return (
    <Layout>
      <Fragment>
        <ImagesContext.Consumer>
          {allImages => (
            <SEO
              mainImage={get([mainImage, 'fluid', 'src'], allImages) || mainImage}
              path={path}
              title={`${title} – an article by 500Tech`}
              description=""
            />
          )}
        </ImagesContext.Consumer>

        <PostTitle rtl={rtl}>{title}</PostTitle>
        <Author rtl={rtl}>
          {author}
        </Author>
        <LastUpdated rtl={rtl}>
          {rtl ? 'עודכן לאחרונה ב' : 'Last updated on'} {date}
        </LastUpdated>

        <Article rtl={rtl}>{renderAst(data.post.html)}</Article>

        <Link to="/articles">Back to all articles</Link>
      </Fragment>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html: htmlAst
      frontmatter {
        path
        title
        date,
        author,
        mainImage
      }
    }
  }
`

